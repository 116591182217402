<template>
  <header>
    <router-link class="-no-hover" to="/dashboard"><img src="/img/logo.svg"
        alt="logo smart crypto service" /></router-link>
    <div class="user-block">
      <router-link class="profile" active-class="active" to="/profile">
        <Icon class="avatar" :icon="`avatar`" />
      </router-link>
    </div>
    <div class="mobile-menu">
      <button class="icon" @click="toggleNavBar()">
        <icon :icon="showNavBar ? 'close' : 'menu'" />
      </button>
    </div>
  </header>
</template>

<script>
import Icon from '@/components/Icon.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'navbar',
  computed: {
    ...mapGetters(["showNavBar"])
  },
  methods: {
    ...mapMutations(["toggleNavBar"])
  },
  components: { Icon }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
header {
  top: 0;
  background: var(--base-900);
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  box-shadow: 2px 2px 16px 0px rgba(44, 44, 44, 0.15);

  @include media(height, (var(--header-mobile-height), var(--header-pc-height)));
  @include media(padding, (12px 16px, 16px 32px));

  .mobile-menu {
    .icon {
      height: 30px;
      width: 30px;
    }

    @include media(display, (block, none));
  }

  .user-block {
    margin-left: auto;
    align-items: center;
    @include media(display, (none, flex));

    .lang {
      display: flex;
      align-items: center;
      margin-right: 24px;
      font-weight: 500;
      color: var(--base-400);

      img {
        margin-left: 5px
      }
    }

    .profile {
      color: var(--base-100);
      box-shadow: none;

      &:hover,
      &.active {
        color: var(--primary-300);
      }

      .avatar {
        width: 40px;
        height: 40px;
      }

    }
  }
}
</style>
