<template>
  <div class="wrap-container-login">
    <div class="container-login">
      <router-link class="logo" to="/"></router-link>
      <div class="content-login">
        <div class="left">
          <router-view />
        </div>
        <div class="right"><img src="/img/login/pc.png" alt="" /></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrap-container-login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 20px);
}

.container-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  padding: 15px 0;

  @include media(width, (calc(100% - 32px), ""));


  .logo {
    background: url("~/public/img/login/logo.svg") 50% 50% / cover;
    box-shadow: none;
    @include media(width, (207px, 267px));
    @include media(height, (92px, 118px));
  }

  .content-login {
    background: var(--base-0);
    border-radius: 20px;
    box-shadow: 4px 4px 10px 0px rgba(44, 44, 44, 0.10);
    display: flex;

    @include media(margin-left, ("", -80px));
    @include media(flex-direction, (column, ""));
    @include media(width, (100%, ""));

    .left {
      display: flex;
      padding: 40px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      align-self: stretch;

      @include media(padding, (16px, 40px));
      @include media(width, (100%, 408px));

      button {
        width: 100%;
      }

      .description {
        font-size: 14px;
        color: var(--base-700, #6A6F7F);
      }
    }

    .right {
      display: flex;
      align-items: center;

      @include media(justify-content, (center, ""));
      @include media(padding, (16px, 40px 0));
      @include media(width, (100%, 332px));

      img {
        width: 422px;
        height: 255.458px;

        @include media(width, (280px, 422px));
        @include media(height, (168.995px, 255.458px));
      }
    }
  }
}
</style>