<template>
  <transition>
    <div v-if="show.is" class="modal__bg">
      <div class="modal">
        <div class="modal__header">
          <button class="icon modal__close" @click="show.is = false">
            <Icon :icon="`close`" />
          </button>
          <h3>
            <slot name="header"></slot>
          </h3>
        </div>

        <div class="modal__content">
          <slot></slot>
        </div>

        <div class="modal__footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from '../Icon.vue';

export default {
  props: {
    show: {
      type: Object,
    },
  },
  components: { Icon }
}
</script>

<style lang="scss" scoped>
.modal {
  background: var(--base-0);
  max-width: 540px;
  margin: 32px auto;
  border-radius: 8px;
  @include media(padding, (20px 16px, 32px 40px));

  position: relative;
  overflow: hidden;

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--transparent-64);
    z-index: 100;
    overflow: auto;
  }

  &__content {
    margin-top: 16px;
  }

  &__footer {
    margin-top: 24px;
    text-align: right;
  }

  .icon {
    height: 24px;
    width: 24px;
  }

  &__close {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
</style>