<template>
  <NavBar />
  <Header />

  <div class="wrap-container">
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import NavBar from './NavBar.vue';

export default {
  name: 'MainLayout',
  components: { NavBar, Header }
}
</script>

<style lang="scss">
.wrap-container {
  @include media(padding-top, (var(--header-mobile-height), var(--header-pc-height)));
  @include media(padding-left, (0, var(--nav-pc-width)));
}

.container {
  max-width: var(--max-width-content);
  margin: auto;
  @include media(padding, (10px, 12px 16px, 28px 32px));
}

.content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 5px;

  @media screen and (max-width: 450px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
</style>