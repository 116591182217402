<template>
  <nav :class="showNavBar ? 'show' : ''">
    <router-link class="account" to="/profile">
      <img src="/img/user-circle.svg" alt="" />
      admin@mail.ru
    </router-link>
    <div class="menu">
      <div v-for="item in links" :key="item.icon" class="item">
        <router-link @click="toggleNavBar()" :active-class="item.url ? 'active' : ''" :to="item.url">
          <div class="icon-wrap">
            <icon :icon="item.icon" />
          </div>
          <span>{{ item.title }}</span>
          <svg v-if="item?.sublinks" class="icon plus" @click="isShowAddBotModal.is = true">
            <use xlink:href="/img/icons/icons.svg#plus"></use>
          </svg>
        </router-link>

        <div v-if="item?.sublinks" class="dropmenu">
          <router-link @click="toggleNavBar()" active-class="active" v-for="wallet in item.sublinks" :key="wallet.icon"
            :to="wallet.url">
            <img class="wallet-icon" :src="`/img/dashboard/${wallet.icon}.svg`" alt="" />
            <span>{{ wallet.title }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="tarif">
      <div class="tarif-title">
        Тариф Pro
        <button class="icon">
          <Icon :icon="`arrow-up`" />
        </button>
      </div>
      <div class="tarif-content">
        <div class="tarif-item"><span>Кошельки</span><span>1/10</span></div>
        <div class="tarif-item"><span>Биржи</span><span>2/5</span></div>
        <div class="tarif-item"><span>Боты</span><span>10/50</span></div>
        <div class="tarif-item"><span>Действует до</span><span>10.09.2023</span></div>
      </div>
    </div>
    <div class="bottom">
      <router-link class="btn icon" to="/">
        <Icon :icon="`exit`" />
        Выйти
      </router-link>
      <div class="lang">
        <span>EN</span>
        <img src="/img/icons/en.svg" alt="" />
      </div>
    </div>
  </nav>
  <add-wallet :show="isShowAddBotModal" />
</template>

<script>
import Icon from '@/components/Icon.vue';
import AddWallet from '@/components/modals/AddWallet.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'NavBar',
  data() {
    return {
      isShowAddBotModal: { is: false },
      links: [
        { title: "Дашбоард", icon: "dashboard", url: "/dashboard" },
        { title: "Операции", icon: "transactions", url: "/operations" },
        { title: "Боты", icon: "bot", url: "/bots" },
        { title: "Тариф", icon: "tarif", url: "/rates" },
        { title: "Аналитика", icon: "chart", url: "/analitycs" },
        // { title: "p2p калькулятор", icon: "calculator", url: "/calculator" },
        {
          title: "Кошельки", icon: "wallet", url: "", sublinks: [
            { title: "ByBit", icon: "bybit", url: "/wallet/bybit" },
            { title: "Binance", icon: "binance", url: "/wallet/binance" },
          ]
        },
      ]
    };
  },
  computed: {
    ...mapGetters(["showNavBar"])
  },
  methods: {
    ...mapMutations(["toggleNavBar"]),

  },
  components: { AddWallet, Icon }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
nav {
  position: fixed;
  background: #fff;
  flex-direction: column;
  box-shadow: 2px 2px 16px 0px rgba(44, 44, 44, 0.15);
  display: flex;
  transition: var(--transition);
  z-index: 100;
  bottom: 0;

  @include media(left, (calc(0px - var(--nav-mobile-width)), 0));
  // @include media(height, (100vh, calc(100vh - var(--header-pc-height))));
  @include media(top, (0, var(--header-pc-height)));
  @include media(width, (var(--nav-mobile-width), var(--nav-pc-width)));

  &.show {
    left: 0
  }

  .account {
    padding: 16px 16px 16px 20px;
    background: var(--base-900);
    display: flex;
    align-items: center;
    color: var(--base-0);
    font-weight: 400;
    min-height: var(--header-mobile-height);
    @include media(display, (flex, none));

    img {
      margin-right: 10px;
      height: 24px;
      width: 24px;

    }
  }

  .menu {
    padding: 16px 0;
    height: 100%;
    overflow: auto;

    .dropmenu {
      a {
        padding: 8px 32px 8px 78px;
      }
    }

    a {
      color: var(--base-900);
      display: flex;
      align-items: center;
      height: 48px;
      padding: 8px 32px;
      box-shadow: none;
      position: relative;
      font-weight: 400;

      .wallet-icon {
        height: 24px;
        width: 24px;
        margin-right: 12px;
      }

      .icon-wrap {
        display: flex;
        height: 34px;
        width: 34px;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        background: var(--base-100);
        border-radius: 50%;
      }

      .icon {
        height: 20px;
        width: 20px;
        color: var(--base-600);
        transition: var(--transition);

        &.plus {
          margin-left: auto;

          &:hover {
            color: var(--primary-500);
          }
        }
      }

      &::before {
        content: "";
        width: 0px;
        height: 36px;
        position: absolute;
        top: 6px;
        left: 0;
        border-radius: 0px 4px 4px 0px;
        background: var(--primary-500);
        transition: var(--transition);

      }

      &.active,
      &:hover {
        font-weight: 500;

        &:not(:hover)::before {
          content: "";
          width: 4px;
          background: var(--primary-500);
        }

        .icon:not(.plus) {
          color: var(--primary-500);
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    background: var(--base-900);
    padding: 8px 16px;
    height: 56px;

    .btn:hover {
      color: var(--base-0);
      box-shadow: none;
    }

    svg.icon {
      height: 24px;
      width: 24px;
      padding-right: 5px;
    }

    .lang {
      display: flex;
      align-items: center;
      font-weight: 500;
      color: var(--base-0);
      margin-left: auto;
      font-size: 14px;

      img {
        margin-left: 5px
      }
    }
  }

  .tarif {
    padding: 24px;
    box-shadow: 0 -4px 0 var(--base-100);
    color: var(--base-800);

    &-title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 700;
    }

    &-content {
      font-size: 14px;
    }

    &-item {
      margin: 8px 0;
      display: flex;
      justify-content: space-between;

      &:first-child {
        margin-top: 16px;
      }
    }
  }
}
</style>
