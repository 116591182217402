<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import Login from "@/views/layouts/Login";
import Main from "@/views/layouts/Main";

export default {
  components: {
    Login,
    Main,
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "Main");
    },
  }
}
</script>

<style lang="scss">
@import "./assets/styles/base";
</style>
