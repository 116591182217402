<template>
  <Modal :show="show">
    <template v-slot:header>
      Добавление {{ selectWallet ? wallets[selectWallet - 1].name : "кошелька" }}
    </template>

    <div class="wallets__step1" v-if="!selectWallet">
      <input type="text" placeholder="Поиск" v-model="search" />
      <div class="wallets">
        <transition-group name="fade">
          <div @click="selectWallet = i + 1" class="wallets__item" v-for="(item, i) in filteredWallets" :key="item.name">
            <img :src="`/img/dashboard/${item.icon}.png`" alt="">
            <div class="wallets__name">{{ item.name }}</div>
          </div>
        </transition-group>
      </div>
    </div>

    <div class="wallets__step2" v-else>
      <input type="text" placeholder="API key">
      <input type="text" placeholder="Secret key">
      <p>Не знаете как добавить кошелек?
        <a target="_blank" href="#">Инструкция подключенгия кошелька</a>
      </p>
    </div>

    <template v-slot:footer>
      <button v-if="selectWallet" @click="selectWallet = ''" class="small icon">Назад</button>
      <button v-if="selectWallet" @click="$router.push(`/wallet/binance`); show.is = false"
        class="small">Добавить</button>
    </template>
  </Modal>
</template>

<script>
import Modal from './Modal.vue';
import { Chart } from "vue-tradingview-widgets";

export default {
  components: { Modal, Chart },
  data() {
    return {
      tabs: [{ name: "Настрйока бота" }, { name: "График по паре" }],
      activeTab: 0,
      search: "",
      selectWallet: "",
      wallets: [
        { name: "Binance", icon: "binance" },
        { name: "Metamask", icon: "metamask" },
        { name: "Binance2", icon: "binance" },
        { name: "Metamask2", icon: "metamask" },
        { name: "Binance3", icon: "binance" },
        { name: "Metamask3", icon: "metamask" },
        { name: "Binance4", icon: "binance" },
        { name: "Metamask4", icon: "metamask" },

      ],
      optionsChart: {
        "autosize": true,
        "symbol": "BINANCE:BTCUSDT",
        "interval": "1",
        "theme": "light",
        "style": "1",
        "locale": "ru",
        "withdateranges": true,
        "save_image": false,
      }
    }
  },
  computed: {
    filteredWallets() {
      return this.search ? this.wallets.filter(i => i.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0) : this.wallets
    }
  },
  props: {
    show: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
.wallets {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
  gap: 8px;

  &__step1 {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;
  }

  &__step2 {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__item {
    padding: 8px 12px;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    background: var(--base-100);
    border: 1px solid var(--base-100);
    cursor: pointer;
    transition: var(--transition);

    &:hover {
      border: 1px solid var(--primary-300);
      box-shadow: 2px 2px 16px 0px rgba(44, 44, 44, 0.15);
    }

    img {
      width: 28px;
    }
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__name {
    padding-left: 8px;
  }
}
</style>