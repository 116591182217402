<template>
  <svg class="icon">
    <use :xlink:href="`/img/icons/icons.svg#${icon}`"></use>
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ""
    },
  },
  setup() {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>