import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "login",
    meta: { layout: "login" },
    component: () => import("../views/login/Login.vue"),
  },
  {
    path: "/registration",
    name: "registration",
    meta: { layout: "login" },
    component: () => import("../views/login/Registration.vue"),
  },
  {
    path: "/registration-confirm",
    name: "registrationconfirm",
    meta: { layout: "login" },
    component: () => import("../views/login/RegistrationСonfirm.vue"),
  },
  {
    path: "/login-confirm",
    name: "loginconfirm",
    meta: { layout: "login" },
    component: () => import("../views/login/LoginСonfirm.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { layout: "main" },
    component: () => import("../views/dashboard/Dashboard.vue"),
  },
  {
    path: "/wallet/bybit",
    name: "bybit",
    meta: { layout: "main" },
    component: () => import("../views/dashboard/Wallet.vue"),
  },
  {
    path: "/wallet/binance",
    name: "binance",
    meta: { layout: "main" },
    component: () => import("../views/dashboard/Wallet.vue"),
  },
  {
    path: "/bots",
    name: "bots",
    meta: { layout: "main" },
    component: () => import("../views/dashboard/Bots.vue"),
  },
  {
    path: "/bots/:id",
    name: "bot",
    meta: { layout: "main" },
    component: () => import("../views/dashboard/Bot.vue"),
  },
  {
    path: "/operations",
    name: "operations",
    meta: { layout: "main" },
    component: () => import("../views/dashboard/Operations.vue"),
  },
  {
    path: "/analitycs",
    name: "analitycs",
    meta: { layout: "main" },
    component: () => import("../views/dashboard/Analitycs.vue"),
  },
  {
    path: "/rates",
    name: "rates",
    meta: { layout: "main" },
    component: () => import("../views/dashboard/Rates.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: { layout: "main" },
    component: () => import("../views/dashboard/Profile.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
