import { createStore } from "vuex";

export default createStore({
  state: {
    showNavBar: false,
  },
  getters: {
    showNavBar: (s) => s.showNavBar,
  },
  mutations: {
    toggleNavBar(s) {
      s.showNavBar = !s.showNavBar;
    },
  },
  actions: {},
  modules: {},
});
